import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import { PaddingEnum } from '../b-layout';
import InlineLink from '../inline-link';
import Image from '../image';

export const Footer = () => {
  return (
    <Box
      as="footer"
      sx={{
        pt: '40px',
        px: PaddingEnum.HORIZONTAL_NORMAL,
        width: '100%',
      }}
    >
      <Box
        sx={{
          pt: ['50px', '80px'],
          pb: ['80px', '100px'],
          borderTop: '1px solid #E6E6E6',
          width: '100%',
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Text fontSize="16px" lineHeight="1.5em" color="#626262">
          <InlineLink href="tel:+16042276551555">+1 (604) 227-6551 555</InlineLink>
          <br />
          Burrard St, Vancouver, BC V7X 1M8, Canada
          <br />© 2011–{new Date().getFullYear()} Uploadcare Inc.
        </Text>
        <Box
          as={Image}
          src="https://ucarecdn.com/87e5183d-c92d-46cd-b45f-6b4769cfeb02/logocircle.svg"
          alt="Uploadcare logo"
          sx={{
            height: '34px',
            width: '34px',
            mt: ['30px', '0px'],
          }}
        />
      </Box>
    </Box>
  );
};
