import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { PaddingEnum } from '../b-layout';

const defaultLinkRenderer = (props) => <a {...props} />;

const StyledLogoWrapper = styled.div`
  display: inline-flex;
`;

export const Navbar = ({ withClickableLogo = false, renderLink = defaultLinkRenderer }) => {
  const LogoWrapper = withClickableLogo
    ? ({ children, ...props }) => renderLink({ href: '/', children, ...props })
    : 'div';

  return (
    <Box
      as="nav"
      width="100%"
      height={[50, 50, 70, 70]}
      sx={{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'min-content auto',
        px: PaddingEnum.HORIZONTAL_NORMAL,
      }}
    >
      <StyledLogoWrapper as={LogoWrapper}>
        <Box
          as="img"
          alt="Uploadcare logo"
          src="https://ucarecdn.com/8eca784b-bbe5-4f7e-8cdf-98d75aab8cec/logotransparent.svg"
          sx={{
            width: [127, 127, 150, 150],
          }}
        />
      </StyledLogoWrapper>
    </Box>
  );
};

Navbar.propTypes = {
  withClickableLogo: PropTypes.bool,
  renderLink: PropTypes.func,
};
