import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Description, Title } from '../b-typography';

export const ListWithVisualItem = ({ icon, title, description }) => (
  <Box
    as="li"
    sx={{
      display: 'grid',
      gridTemplateColumns: ['75px 1fr', '90px 1fr', '80px 1fr'],
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '56px',
        width: '56px',
      }}
    >
      {icon}
    </Box>
    <Box maxWidth="346px">
      <Title mb="10px" mt="14px">
        {title}
      </Title>
      <Description>{description}</Description>
    </Box>
  </Box>
);

ListWithVisualItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
