import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { BlImage } from '../bl-image';

export const ListWithVisualIllustration = ({ src, sx, ...props }) => (
  <Box
    as={BlImage}
    data-blink-src={src}
    sx={{
      width: '100%',
      maxHeight: ['400px', '400px', '490px', '470px'],
      objectFit: 'cover',
      ...sx,
    }}
    {...props}
  />
);

ListWithVisualIllustration.propTypes = {
  src: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
