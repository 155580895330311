import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PaddingEnum } from '../b-layout';
import { H2 } from '../b-typography';

const Content = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: ['center', 'flex-start'],
      justifyContent: 'center',
      height: '100%',
    }}
  >
    {children}
  </Box>
);
const ItemList = ({ children }) => (
  <Box
    as="ul"
    sx={{
      display: 'grid',
      gridGap: ['30px', '24px', '40px', '46px'],
      padding: 0,
    }}
  >
    {children}
  </Box>
);

export const ListWithVisual = ({ title, illustration, cta, items, sx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateAreas: [
          `
            "title"
            "illustration"
            "content"
          `,
          `
            "title title"
            "illustration content"
          `,
          `
            "title content"
            "illustration content"
          `,
        ],
        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        gridGap: ['30px', '60px 50px', '70px 60px', '70px 80px'],
        px: PaddingEnum.HORIZONTAL_NORMAL,
        ...sx,
      }}
    >
      <Box
        sx={{
          gridArea: 'title',
          display: 'flex',
          justifyContent: ['center', 'center', 'flex-end'],
        }}
      >
        <Box sx={{ maxWidth: ['100%', '526px', '345px', '415px'] }}>
          <H2>{title}</H2>
        </Box>
      </Box>
      <Box sx={{ gridArea: 'illustration' }}>{illustration}</Box>
      <Box sx={{ gridArea: 'content' }}>
        <Content>
          <ItemList>
            {items}
            <Box
              sx={{
                width: '100%',
                paddingLeft: ['75px', '90px', '80px'],
                paddingTop: ['0px', '6px', '25px', '14px'],
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  minWidth: '210px',
                  flexDirection: 'column',
                }}
              >
                {cta}
              </Box>
            </Box>
          </ItemList>
        </Content>
      </Box>
    </Box>
  );
};

ListWithVisual.propTypes = {
  title: PropTypes.string.isRequired,
  illustration: PropTypes.node.isRequired,
  cta: PropTypes.node.isRequired,
  items: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
