import styled from 'styled-components';

export const InlineLink = styled.a`
  &,
  &:active,
  &:visited,
  &:visited:active {
    color: inherit;
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: inherit;
    text-decoration: underline;
  }
`;
