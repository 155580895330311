import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import { PaddingEnum } from '../b-layout';
import { H1, H2 } from '../b-typography';
import { HeadVariantsEnum } from './HeadVariantsEnum';

const V3Heading = (props) => (
  <Text
    as="h2"
    fontSize={['32px', '36px', '40px', '44px']}
    lineHeight="1.2em"
    fontWeight="bold"
    {...props}
  />
);

const Description = (props) => (
  <Text
    mt="30px"
    fontSize={['20px', '22px', '24px', '26px']}
    lineHeight="1.4em"
    fontWeight="500"
    color="#B5B5B5"
    {...props}
  />
);

const variantRenderer = {
  [HeadVariantsEnum.V1]: ({ title, description, level = 'h1' }) => (
    <>
      <H1 as={level}>{title}</H1>
      {description && <Description>{description}</Description>}
    </>
  ),
  [HeadVariantsEnum.V2]: ({ title, description, level = 'h2' }) => (
    <>
      <H2 as={level}>{title}</H2>
      {description && <Description>{description}</Description>}
    </>
  ),
  [HeadVariantsEnum.V3]: ({ title, description, level = 'h2' }) => (
    <V3Heading as={level}>
      {title}{' '}
      <Text as="span" color="#B5B5B5">
        {description}
      </Text>
    </V3Heading>
  ),
};

export const Head = ({
  icon,
  title,
  description,
  cta,
  level,
  variant = HeadVariantsEnum.V1,
  sx,
}) => {
  const Variant = variantRenderer[variant];

  return (
    <Box sx={{ px: PaddingEnum.HORIZONTAL_WIDE, ...sx }}>
      {icon && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: ['29px', '29px', '30px', '31px'],
            width: ['78px', '78px', '86px', '96px'],
            height: ['78px', '78px', '86px', '96px'],
            marginLeft: '-8px',
          }}
        >
          {icon}
        </Box>
      )}

      <Variant title={title} description={description} level={level} />

      {cta && (
        <Box
          sx={{
            mt: '40px',
            display: 'grid',
            gridGap: 10,
            gridAutoFlow: ['row', 'column'],
            gridAutoColumns: ['100%', '210px'],
            justifyContent: 'flex-start',
          }}
        >
          {cta}
        </Box>
      )}
    </Box>
  );
};

Head.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cta: PropTypes.node,
  icon: PropTypes.node,
  variant: PropTypes.number,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  sx: PropTypes.object,
};
