import { Footer } from '@uc-common/b-footer';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import styled from 'styled-components';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import Navbar from '@uc-common/b-navbar';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Testimonials, TestimonialsItem } from '@uc-common/b-testimonials';
import { BuzzLogoIdEnum } from '@uc-common/buzz-logo';
import { Columns, ColumnItemInlineIcon } from '@uc-common/b-columns';
import {
  ListWithVisual,
  ListWithVisualItem,
  ListWithVisualIllustration,
} from '@uc-common/b-list-with-visual';
import { Icon } from '@uc-common/b-icon';
import { Customers } from '@uc-common/b-customers';
import { VisualBig } from '@uc-common/b-visual-big';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Link } from '../../components/Link';

export const frontmatter = {
  title: 'The ultimate file uploader for your website or app',
  description:
    'Enable fast and secure uploads for your online project in a snap with Uploadcare. It takes minutes to integrate File Uploader into your product. Try free!',
  noindex: true,
};

const CTACard = styled.div`
  background: radial-gradient(120% 120% at 99% 117%, #ffebfa 0%, #f1f3fd 63%, #f6f7f8 100%);
  padding-top: 60px;
  padding-bottom: 60px;
`;

const FileUploader = ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <BLayout header={<Navbar />} footer={<Footer />}>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V1}
            title="The ultimate file uploader by&nbsp;Uploadcare"
            description="Easily set up&nbsp;an&nbsp;uploading flow in&nbsp;your website or&nbsp;app with a&nbsp;user-friendly widget and a&nbsp;rich Upload API."
            cta={
              <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                Get started
              </Button>
            }
          />
          <VisualBig
            src="https://ucarecdn.com/07880f6a-eb8c-4881-8711-e741328da287/uploaderkeyvisual.png"
            sx={{
              paddingTop: PaddingEnum.VERTICAL_M,
              minHeight: ['150px', '330px', '420px', '490px'],
            }}
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <ListWithVisual
            title="Made with developers in&nbsp;mind"
            illustration={
              <ListWithVisualIllustration src="https://ucarecdn.com/61984571-1783-4fa9-9626-b15909ab0b38/BenefitsVisual12801.svg" />
            }
            cta={
              <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                Start building
              </Button>
            }
            items={
              <>
                <ListWithVisualItem
                  icon={<Icon id="lightning-boxed" />}
                  title="Integrate in&nbsp;minutes"
                  description="A few clicks, a couple of code&nbsp;bits, and your website or&nbsp;app get integrated with the Uploadcare file uploader."
                />
                <ListWithVisualItem
                  icon={<Icon id="settings-boxed" />}
                  title="Make it&nbsp;look native"
                  description="File Uploader was&nbsp;designed to&nbsp;inherit styles from your page, but you can customize it&nbsp;with CSS if&nbsp;you want. "
                />
                <ListWithVisualItem
                  icon={<Icon id="face-happy-boxed" />}
                  title="Hassle-free maintenance"
                  description="Get rid of&nbsp;the maintenance burden to&nbsp;spend time on&nbsp;your product, not fixing failed file uploads."
                />
                <ListWithVisualItem
                  icon={<Icon id="medal-boxed" />}
                  title="Go&nbsp;live in&nbsp;a&nbsp;snap"
                  description="In&nbsp;a&nbsp;few hours, you&rsquo;ll have a&nbsp;working solution for handling user-generated content completely at&nbsp;your service: a multi&nbsp;image&nbsp;uploader, a video&nbsp;uploader, a document&nbsp;uploader, uploader for&nbsp;any kind of files."
                />
              </>
            }
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head variant={HeadVariantsEnum.V2} title="More than just an “Upload File” button" />
          <Columns sx={{ paddingTop: PaddingEnum.VERTICAL_M }}>
            <ColumnItemInlineIcon
              title="Powerful API"
              description="Create and manage custom file-handling workflows that perfectly serve your project needs."
              icon={<Icon id="code-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Instant upload"
              description="No&nbsp;matter how large&nbsp;&mdash; 5&nbsp;megabytes or&nbsp;5&nbsp;terabytes&nbsp;&mdash; files are fetched in&nbsp;a&nbsp;snap."
              icon={<Icon id="rocket-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Built-in image editor"
              description="Crop, rotate, and enhance photos right on&nbsp;the spot."
              icon={<Icon id="settings-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Web uploading network"
              description="All the uploads are accelerated by&nbsp;a&nbsp;CDN, so&nbsp;you can receive files up&nbsp;to&nbsp;4X&nbsp;faster."
              icon={<Icon id="atom-boxed" />}
            />
            <ColumnItemInlineIcon
              title="14&nbsp;upload sources"
              description="Upload files from your device or&nbsp;right from Google Drive, Dropbox or&nbsp;Instagram."
              icon={<Icon id="arrows-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Secure file upload workflows"
              description="Establish HIPAA-compliant workflows by&nbsp;controlling who can upload and access uploaded files, and when."
              icon={<Icon id="shield-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Easy to integrate with your project"
              description="React file uploader, HTML5 file uploader, Next.js file uploader, Python file uploader."
              icon={<Icon id="code-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Upload any files"
              description="Image uploader, video uploader, document uploader, multipart uploader, multi image uploader, large file uploader, music file uploader."
              icon={<Icon id="settings-boxed" />}
            />
            <ColumnItemInlineIcon
              title="Free to use"
              description="Free image uploader without verification, free file uploader after profile verification. Get started on your pet project with Uploadcare."
              icon={<Icon id="atom-boxed" />}
            />
          </Columns>
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head
            variant={HeadVariantsEnum.V2}
            title="20,000+ teams handle their images, videos and other files with Uploadcare"
          />
          <Customers sx={{ paddingTop: PaddingEnum.VERTICAL_M }} />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head variant={HeadVariantsEnum.V3} title="Here&rsquo;s what their success sounds like" />
          <Testimonials sx={{ paddingTop: PaddingEnum.VERTICAL_M }}>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.MOZILLA}
              logoWidths={[124, 124]}
              customerTitle={
                <>
                  Chris Van Wiemeersch,
                  <br />
                  UX Engineer
                </>
              }
              customerAvatarUuid="6bf9903e-584d-459c-b621-819a5bf4910a"
            >
              It&rsquo;s the most painless, enjoyable flow out there. Awesome product. We&nbsp;all
              love Uploadcare dearly.
            </TestimonialsItem>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.YCOMBINATOR_BW}
              logoWidths={[144, 144]}
              customerTitle={
                <>
                  Finbarr Taylor,
                  <br />
                  Software Engineer
                </>
              }
              customerAvatarUuid="d4d6a4b0-3f89-41b0-b9bc-204db14dd355"
            >
              Uploadcare makes uploading images a&nbsp;piece of&nbsp;cake. I&rsquo;ve been using
              it&nbsp;in&nbsp;production for over a&nbsp;year and it&nbsp;has been awesome. The
              support is&nbsp;really good and the product is&nbsp;solid.
            </TestimonialsItem>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.PANDADOC_BW}
              logoWidths={[124, 124]}
              customerTitle={
                <>
                  Serge Barysiuk,
                  <br />
                  Co-Founder and CTO
                </>
              }
              customerAvatarUuid="2640d2e5-c0a0-4cb0-a7ed-25e14c8cf421"
            >
              Uploadcare is&nbsp;a&nbsp;perfect example of&nbsp;a&nbsp;laser-focused application
              which solves a&nbsp;real problem. And it&nbsp;worked and works perfectly for&nbsp;us.
            </TestimonialsItem>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.UNIDESK_BW}
              logoWidths={[124, 124]}
              customerTitle={
                <>
                  Chris Midgley,
                  <br />
                  Founder and CTO
                </>
              }
              customerAvatarUuid="2a8f35eb-519b-4d0d-add2-d61078d2156c"
            >
              In&nbsp;just days we&nbsp;implemented a&nbsp;cross-browser, reliable large file upload
              and management solution into our site. Even better, we&rsquo;ve had no&nbsp;problems
              or&nbsp;usability issues since going live.
            </TestimonialsItem>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.SNUGGPRO_BW}
              logoWidths={[124, 124]}
              customerTitle={
                <>
                  Jeff Friesen,
                  <br />
                  CTO
                </>
              }
              customerAvatarUuid="0e179599-2f9a-44ab-b088-ec507873a109"
            >
              Uploadcare allowed&nbsp;us to&nbsp;get photo upload and cropping into our app within
              hours. More importantly, they are super responsive with support. We&rsquo;ve been
              running this in&nbsp;production for months and our users love&nbsp;it.
            </TestimonialsItem>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.SPLITMETRICS_BW}
              logoWidths={[144, 144]}
              customerTitle={
                <>
                  Max Kamenkov,
                  <br />
                  CEO
                </>
              }
              customerAvatarUuid="2d93b0e0-5cca-4452-a1de-bc7dcdbdb6c5"
            >
              Uploadcare is&nbsp;a&nbsp;great example of&nbsp;an&nbsp;embedded solution that
              allows&nbsp;us to&nbsp;save lots of&nbsp;time and effort. It&nbsp;is&nbsp;essential
              for startups.
            </TestimonialsItem>
          </Testimonials>
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_XL,
            paddingBottom: PaddingEnum.VERTICAL_L,
          }}
        >
          <CTACard>
            <Head
              variant={HeadVariantsEnum.V2}
              title="All file uploading capabilities under one roof"
              description="Get an all-in-one file uploader out of the box, which saves development time and costs, and helps you bring your product to&nbsp;market faster."
              cta={
                <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                  Try free
                </Button>
              }
            />
          </CTACard>
        </Section>
      </BLayout>
    </CommonLayout>
  );
};

export default FileUploader;
